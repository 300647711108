<template>
  <v-card
    class="v-card--wizard"
    elevation="12"
    max-width="400"
  >
    <v-tabs
      ref="tabs"
      v-model="internalValue"
      background-color="grey lighten-5"
      color="white"
      grow
      slider-size="70"
    >
      <v-tabs-slider
        class="mt-1"
        color="primary"
      />

      <v-tab
        v-for="(item, i) in items"
        :key="i"
        :ripple="false"
        :disabled="
          !availableSteps.includes(i) ||
          (i == 1 && valueSecondTab == 'disabled') ||
          (i == 0 && valueSecondTab == 'active')
        "
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <div class="my-6" />

    <v-card-text>
      <v-tabs-items v-model="internalValue">
        <slot />
      </v-tabs-items>
    </v-card-text>

    <v-card-actions class="pb-4 pa-4 d-flex">
      <v-spacer class="flex-shrink-1" />

      <router-link 
        :to="actionLink.to"
        style="margin-right: 20px;"
        v-if="actionLink && (actionLink.showIf || actionLink.showIf === undefined)"
      ><small>{{ actionLink.text }}</small></router-link>

      <v-btn
        v-if="internalValue === 0"
        :disabled="!availableSteps.includes(internalValue + 1)"
        color="primary"
        class="flex-shrink-1"
        min-width="100"
        @click="$emit('click:next')"
      >
        Próximo
      </v-btn>

      <v-btn
        v-else
        :disabled="disabledBtnLogar"
        :loading="loading"
        color="primary"
        class="flex-shrink-1"
        min-width="100"
        @click="$emit('click:next')"
      >
        {{ btnLogarLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable';

export default {
  name: 'BaseLoginMaterialWizard',

  mixins: [Proxyable],

  props: {
    actionLink: {
      type: Object,
      required: false,
    },
    availableSteps: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    disabledBtnLogar: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    btnLogarLabel: {
      type: String,
      default: 'Entrar'
    }
  },

  data() {
    return {
      valueSecondTab: 'disabled'
    };
  },

  methods: {
    activateSecondTab() {
      this.valueSecondTab = 'active';
    }
  }
};
</script>

<style lang="sass">
.v-card--wizard
  overflow: visible

  .v-tabs-bar
    height: 56px
    padding: 0 0px

  .v-tabs-slider-wrapper
    overflow: visible

  .v-tabs-slider
    border-radius: 4px

  .v-tabs-slider-wrapper
    contain: initial
    z-index: 0
</style>
